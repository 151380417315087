import React from "react";
import { stoageGetter } from "../helpers";
import "./HypervergeSDK.css";
import apiConfig from "../config/api.config";
import axiosRequest from "../axios-request/request.methods";
import {
  FolderAddOutlined,
  DeleteOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { Button, Upload, message, Image } from "antd";
import { useState } from "react";
const { baseURL } = apiConfig;

const HypervergeSDK = ({
  heading = " Please upload document",
  headingColor = "rgba(0, 0, 0, 0.85)",
  themeBg = "#482c77",
  hintText,
  hintColor = "rgba(0, 0, 0, 0.85)",
  width = "100%",
  height = "100%",
  border = "none",
  borderColor = "transparent",
  borderRadius = "18px",
  handleHyperResponse,
  documentCode,
  documentType,
  documentId
}) => {
  const [hyperResult, setHyperResult] = useState("");
  const [fullName, setFullName] = useState("");
  const [imagePath, setImagePath] = useState("");

  let login_user_data = stoageGetter("user");
  const onBoardHandler = async (KYCResult) => {
    if (!documentId) {
      message.error("Document id is not available");
      return;
    }
    if (!documentType) {
      message.error("Please select Valid ID");
      return;
    }
    if (!documentCode) {
      message.error("Please enter ID Number");
      return;
    }
    let agentMobileNumber = login_user_data.mobileNo;
    let API = `user/hyperverge/tokenGeneration?documentId=${documentId}&documentType=${documentType}&documentCode=${documentCode}`;
    let Response = await axiosRequest.get(API);

    if (Response.errCode === -1) {
      let token = Response.errMsg.accessToken;
      let transactionId = Response.errMsg.transactionId;
      let workflowIDs = Response.errMsg.workflow;
      LaunchSDK(KYCResult, token, transactionId, workflowIDs);
    } else {
    }
  };

  const LaunchSDK = async (KYCResult, Token, transactionId, workflowIDs) => {
    const hyperKycConfigs = new window.HyperKycConfig(
      Token,
      workflowIDs,
      transactionId
    );
    window.HyperKYCModule.launch(hyperKycConfigs, (ResultsHandler) => {
      handleHyperResponse(ResultsHandler);
      setHyperResult(ResultsHandler);
      if (ResultsHandler.status == "auto_approved") {
        setImagePath(ResultsHandler?.details?.imagePath)
        const selectedName = ResultsHandler?.details?.fullName
          ? ResultsHandler?.details?.fullName
          : `${ResultsHandler?.details?.firstName} ${ResultsHandler?.details?.middleName} ${ResultsHandler?.details?.lastName}`;
        setFullName(selectedName);
      } else {
        setImagePath("");
      }
    });
  };

  const resetDoc = () => {
    setHyperResult("");
    setFullName("");
    setImagePath("");
  };

  return (
    <div>
      <p className="fw-bold" style={{ color: headingColor }}>
        <span style={{ color: 'red' }}>* </span>
        {heading}
      </p>
      <div
        className="browser-box"
        style={{ width, height, border, borderColor, borderRadius }}
      >
        <div className="doc-box">
          {
            !imagePath && (
              <div
                className="child11"
                style={{
                  backgroundColor: themeBg,
                  borderColor: themeBg,
                  marginRight: "20px",
                }}
              >
                <FolderAddOutlined style={{ color: 'white' }} />
              </div>
            )
          }
          {
            imagePath && (
          <Image
            width={45}
            src={imagePath}
            style={{marginRight: '10px'}}
          />
            )
          }
          <div className="doc-text-container">
            {fullName && (
              <p className="doc-text" title={fullName}>
                <FileProtectOutlined style={{ marginRight: "10px" }} />
                <span className="signature-name" title={fullName}>
                {fullName}
                </span>
                <DeleteOutlined
                  className="doc-delete-icon"
                  onClick={resetDoc}
                />
              </p>
            )}
          </div>
        </div>

        <Button
          className={`browse-btn ${hyperResult?.status == "auto_approved" ? "points-none" : ""
            }`}
          style={{ backgroundColor: themeBg }}
          onClick={onBoardHandler}
        >
          Browse
        </Button>
      </div>
      <p className="fw-bold" style={{ marginTop: 10, color: hintColor }}>
        {hintText}
      </p>
    </div>
  );
};

export default HypervergeSDK;
