import React, { useEffect, useState } from "react";
import "./App.css";
import { message, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
// import * as actions from "../../../store/actions/index";
import * as actions from "./store/actions/index";
// code for the sharable links start here
import keycloak from './keyclocklogincomponent/keycloak.js'
import { ReactKeycloakProvider } from "@react-keycloak/web";
// import ProductSelect from "./components/OONA/SharableLink/components/ProductSelect"
import Links from "./components/OONA/SharableLink/components/Links";
// code for the sharale links end here
// import ProposalFulfilment from "./components/ProposalFulfilment/ProposalFulfilment";
import Multichannel from "./components/Multichannel";
import DefaultChannel from "./components/DefaultChannel/DefaultChannel";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
// import MotorComprehensive from "./components/OONA/MotorComprehensive/MotorComprehensive.js";
import NotificationComp from "./components/NotificationComp/NotificationComp";
import { CoverageMap } from "istanbul-lib-coverage";
// import PolicyHolderInformationInformation from "./components/OONA/MotorComprehensive/PolicyHolder/PolicyHolder";
// import PolicyHolderInformation from "./components/OONA/MotorComprehensive/components/PolicyHolderInformation";
import MotorPolicyHolderInformation from "./components/OONA/MotorComprehensive/PolicyHolder/PolicyHolder";
import RiskInspection from "./components/OONA/MotorComprehensive/RiskInspection/RiskInspection";

const Master = React.lazy(() => import("./containers/Master/index"));
const MotorComprehensive = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/MotorComprehensive")
);

const LeadMaster = React.lazy(() => import("./containers/LeadMaster/index"));
const QuotationsPoliciesMaster = React.lazy(() =>
  import("./containers/QuotationsPoliciesMaster/index")
);
const AllQuotationDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/AllQuotationDetails")
);

const AllmotorDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/MotorAlldetails")
);

const AllctplDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/CTPLalldetails")
);
const AllCIDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/AllCIdeatils.js")
);
//AllCIDetails

const AlltravelDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/travelalldetails")
);

const AllmotorpolicyDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/allmotorpolicydetails")
);

const AllctplpolicyDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/AllCTPLpolicityDetails")
);
// 

const AllcipolicyDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/AllCIpolicityDetails.js")
);

const AlltravelpolicyDetails = React.lazy(() =>
  import("./components/QuotationsPoliciesCards/AllTravelPolicydetails")
);

const AdvisorMaster = React.lazy(() =>
  import("./containers/AdvisorMaster/index")
);
const sdkhyperverge = React.lazy(() => import("./HypervergeSDK/websdk"));
const AdvisorList = React.lazy(() =>
  import("./components/AdvisorOnboarding/AdvisorCard")
);
const OfflineMessage = React.lazy(() => import("./OfflineMessageU"));

const StatusLead = React.lazy(() =>
  import("./components/StatusLead/StatusLead")
);
const PersonalDetails = React.lazy(() =>
  import("./components/LeadDetails/PersonalDetails/PersonalDetails")
);
const ContactDetails = React.lazy(() =>
  import("./components/LeadDetails/ContactDetails/ContactDetails")
);
const Resource = React.lazy(() =>
  import("./components/OONA/resources/ResourcesOona")
);
const bulkHistoryDetails = React.lazy(() =>
  import("./components/OONA/BulkHistoryDetails/BulkHistoryDetails")
);
const ProfessionalDetails = React.lazy(() =>
  import("./components/LeadDetails/ProfessionalDetails/ProfessionalDeatils")
);
const ExistingInsurance = React.lazy(() =>
  import("./components/LeadDetails/ExistingInsurance/ExistingInsurance")
);
const ProposedProduct = React.lazy(() =>
  import("./components/LeadDetails/ProposedProduct/ProposedProduct")
);
const ProposalDetails = React.lazy(() =>
  import("./components/ProposalDetails/ProposalDetails")
);
const DocumentsUpload = React.lazy(() =>
  import("./components/DocumentsUpload/DocumentsUpload")
);
const History = React.lazy(() => import("./components/History/History"));
const Login = React.lazy(() => import("./components/Login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./components/ForgotPassword/ForgotPassword")
);
const ChangePassword = React.lazy(() =>
  import("./components/ChangePassword/ChangePassword")
);
const Sidebar = React.lazy(() => import("./components/SideBar/SideBar"));
// const ClubsMaster = React.lazy(() =>
//   import("./components/ClubMaster/ClubsMaster")
// );

const MyTeams = React.lazy(() => import("./components/MyTeams/MyTeams"));
// const Birthday = React.lazy(() => import("./components/Birthday/Birthday"));
// const HomePage = React.lazy(() => import("./components/Home/HomePage"));
const CompletedContest = React.lazy(() =>
  import("./components/Contests/CompletedContest")
);
const ActiveContest = React.lazy(() =>
  import("./components/Contests/ActiveContest")
);
const ActiveContestDetails = React.lazy(() =>
  import("./components/Contests/ActiveContestDetails")
);
const AllContestDetails = React.lazy(() =>
  import("./components/Contests/AllContestDetails")
);
const Calendar1 = React.lazy(() =>
  import("./components/Activitity Tracker/ActivityCalender")
);
const AllContest = React.lazy(() => import("./components/Contests/AllContest"));
const CompletedContestDetails = React.lazy(() =>
  import("./components/Contests/CompletedContestDetails")
);
const MyOverallRanking = React.lazy(() =>
  import("./components/Contests/MyOverallRanking")
);

const AllContestDetails1 = React.lazy(() =>
  import("./components/Contests/AllContestDetails")
);
const ActiveContestDetails1 = React.lazy(() =>
  import("./components/Contests/ActiveContestDetails")
);
const CompletedContest1 = React.lazy(() =>
  import("./components/Contests/CompletedContest")
);
const Calendar = React.lazy(() =>
  import("./components/Contests/CalendarEvent")
);
const CalendarMobile = React.lazy(() =>
  import("./components/Contests/CalendarMobile")
);

const KpiDashboard = React.lazy(() =>
  import("./components/KpiDashboard/KpiDashboard")
);

const NotifyPage = React.lazy(() =>
  import("./components/NotificationComp/NotificationComp")
);

const DailyBussiness = React.lazy(() =>
  import("./components/DailyBussiness/DailyBussiness")
);
const AdvisorPitch = React.lazy(() =>
  import("./components/AdvisorPitch/AdvisorPitch")
);
const ForCustomer = React.lazy(() =>
  import("./components/ForCustomer/ForCustomer")
);
const ForSelf = React.lazy(() => import("./components/ForSelf/ForSelf"));
const RenewalReport = React.lazy(() =>
  import("./components/RenewalReport/RenewalReport")
);
const SalesPitch = React.lazy(() =>
  import("./components/SalesPitch/SalesPitch")
);
const ServiceCorner = React.lazy(() =>
  import("./containers/ServiceCorner/index")
);
const ServiceCornerAll = React.lazy(() =>
  import("./components/ServiceCorner/ServiceCorner")
);
const ServiceCornerSelf = React.lazy(() =>
  import("./components/ServiceCorner/ServiceSelf")
);
const ServiceCornerCustomers = React.lazy(() =>
  import("./components/ServiceCorner/ServiceCustomer")
);
const Renewals = React.lazy(() => import("./containers/RenewalMaster/index"));
const RenewalAll = React.lazy(() =>
  import("./components/RenewalCollections/AllRenewals")
);
const RenewalPaid = React.lazy(() =>
  import("./components/RenewalCollections/PaidRenewals")
);
const RenewalUnPaid = React.lazy(() =>
  import("./components/RenewalCollections/UnPaidRenewals")
);
const RenewalLapsed = React.lazy(() =>
  import("./components/RenewalCollections/LapsedRenewals")
);
const RenewalMasterDetails = React.lazy(() =>
  import("./components/RenewalCollections/RenewalDetails")
);
const SalesPendency = React.lazy(() =>
  import("./components/SalesPendency/SalesPendency")
);
const MappedBranches = React.lazy(() =>
  import("./components/MappedBranches/MappedBranches")
);
const LoanProducts = React.lazy(() =>
  import("./components/Products/LoanProducts")
);
// const ProposalFulfilment = React.lazy(() => import('./components/ProposalFulfilment/ProposalFulfilment'))
const PrePaymentReview = React.lazy(() =>
  import("./components/PrePaymentReview/PrePaymentReview")
);
const PaymentOptions = React.lazy(() =>
  import("./components/PaymentOptions/PaymentOptions")
);
const ProposalHistory = React.lazy(() =>
  import("./components/ProposalHistory/ProposalHistory")
);
const CreateTask = React.lazy(() => import("./components/Calender/CreateTask"));
const CreateNewTask = React.lazy(() =>
  import("./components/Calender/CreateNewTask")
);
const UploadDocuments = React.lazy(() =>
  import("./components/UploadDocuments/UploadDocuments")
);
const AdvisorProfile = React.lazy(() =>
  import("./components/AdvisorProfile/AdvisorProfile")
);
const BulkAction = React.lazy(() =>
  import("./components/BulkAction/BulkAction")
);
const TodoMobile = React.lazy(() =>
  import("./components/Activitity Tracker/TodoMobile")
);

const OonaDashboard = React.lazy(() =>
  import("./components/OONA/DashBoard/OonaDashboard")
);

const VehicalType = React.lazy(() =>
  import("./components/OONA/CTPL/VehicalType")
);

const PolicyType = React.lazy(() =>
  import("./components/OONA/CTPL/PolicyType")
);
const VehicalInformation = React.lazy(() =>
  import("./components/OONA/CTPL/VehicalInformation/VehicalInformation")
);

const CustomerInformation = React.lazy(() =>
  import("./components/OONA/CTPL/CustomerInformation/CustomerInformation")
);

const TravelInfo = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelInfo/TravelInfo")
);
const OonaLogin = React.lazy(() =>
  import("./components/OONA/OonaLogin/OonaLogin")
);
const TravelPage = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelPage/TravelPage/TravelPage")
);

const CustomerInformations = React.lazy(() =>
  import("./components/OONA/TRAVEL/CustomerInformation/CustomerInformation")
);

// const Success = React.lazy(() => import("./components/OONA/Success/Success"));
const TechnicalControl = React.lazy(() =>
  import("./components/OONA/Success/TechnicalControl")
);
const Fail = React.lazy(() => import("./components/OONA/Error/Error"));

const NotificationOona = React.lazy(() =>
  import("./components/OONA/NotificationOona/NotificationOona")
);

const PolicyHolder1 = React.lazy(() =>
  import("./components/OONA/CTPL/PolicyHolder/PolicyHolder1")
);
const VehicalInformationPage1 = React.lazy(() =>
  import("./components/OONA/CTPL/VehicalInformation/VehicalInformationPage1")
);
const PolicyGroup = React.lazy(() =>
  import("./components/OONA/CTPL/PolicyGroup/PolicyGroup")
);

const BulkUpload = React.lazy(() =>
  import("./components/OONA/CTPL/BulkUpload/BulkUpload")
);
const FleetUpload = React.lazy(() =>
  import("./components/OONA/CTPL/BulkUpload/FleetUpload")
);

const AllProduct = React.lazy(() =>
  import("./components/OONA/AllProduct/AllProduct")
);

const ConfirmPage = React.lazy(() =>
  import("./components/OONA/CTPL/ConfirmPage/ConfirmPage")
);

const HelpCenter = React.lazy(() =>
  import("./components/OONA/Help Center/HelpCenter")
);

// ==================  MOTOR NEW PAGE DESIGN =================
const MotorSelectedProduct = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/MotorFirstPage/MotorSelectedModal.js")
);

const BulkUploadMotor = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/components/BulkUploadMotor")
);

const PriceCheck1 = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/PriceCheck/PriceCheck")
);

const PolicyGroupDetails = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/PolicyGroupDetails/PolicyGroupDetails"
  )
);

const VehicleInformationInfo = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/VehicleInformationDetails/VehicleInformationDetails"
  )
);

const vehicleQuote = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/VehicleQuote/VehicleQuote")
);

const accessoryQuote = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/AccessoryQuote/AccessoryQuote")
);

const coverageQuote = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/CoverageQuote/CoverageQuote")
);

const policyHolderQuote = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/PolicyHolderInformationQuote/PolicyHolderInformationQuote"
  )
);

const riskInspection = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/RiskInspection/RiskInspection")
);
const riskInspectionSuccess = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/RiskInspection/Success/Success")
);

const MotorConfirmPage = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/MotorConfirmPage/MotorConfirmPage"
  )
);

const inspectionFileUpload = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/RiskInspection/InspectionFileUpload/InspectionFileUpload"
  )
);

const DocumentAlreadyUploaded = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/RiskInspection/Success/DocumentAlreadyUploaded"
  )
);
const quoteInformation = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/QuoteInformation/QuoteInformation"
  )
);

const QuotationSuccess = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/QuotationSuccessPage/QuotationSuccessPage"
  )
);

const CustomerDetail = React.lazy(() =>
  import("./components/OONA/TRAVEL/CustomerDetails/CustomerDetail")
);

const Performance = React.lazy(() =>
  import("./components/OONA/PerformancePage/Performance")
);

const CtplBulkUploadSuccess = React.lazy(() =>
  import("./components/OONA/BulkUploadSuccess/BulkUploadSuccess")
);
const CtplBulkUploadError = React.lazy(() =>
  import("./components/OONA/BulkUploadError/BulkUploadError")
);

const TravelBulkUploadSuccess = React.lazy(() =>
  import("./components/OONA/BulkUploadSuccess/TravelBulkUploadSuccess")
);
const TravelBulkUploadError = React.lazy(() =>
  import("./components/OONA/BulkUploadError/TravelBulkUploadError")
);

const MotorBulkUploadSuccess = React.lazy(() =>
  import("./components/OONA/BulkUploadSuccess/MotorBulkUploadSuccess")
);
const MotorBulkUploadError = React.lazy(() =>
  import("./components/OONA/BulkUploadError/MotorBulkUploadError")
);

const FormalQuoteInfo = React.lazy(() =>
  import("./components/OONA/TRAVEL/FormalQuoteInfo/FormalQuoteInfo")
);
const QuickQuoteInfo = React.lazy(() =>
  import("./components/OONA/TRAVEL/QuickQuoteInfo/QuickQuoteInfo")
);
const SuccessQuickPolicy = React.lazy(() =>
  import(
    "./components/OONA/TRAVEL/QuickQuoteInfo/SuccessQuickPolicy/SuccessQuickPolicy"
  )
);
const QuotePolicyInformation = React.lazy(() =>
  import(
    "./components/OONA/TRAVEL/QuotePolicyInformation/QuotePolicyInformation"
  )
);

const OonaCustomerListing = React.lazy(() =>
  import("./components/OONA/OonaCustomerListing/OonaCustomerListing")
);
const QuoteCode = React.lazy(() =>
  import("./components/OONA/TRAVEL/QuoteCode/QuoteCode")
);

const CustomerList = React.lazy(() =>
  import("./components/OONA/AllCustomerDetails/CustomerList")
);

// POLICY AND CTPL SUCCESS PAGE START HERE START HERE
const SuccessQuotation = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/SuccessQuotation")
);
const CTPLSuccessQuotation = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/CTPLSuccessQuotation")
);
const PolicySuccessTravel = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/policySuccessTravel")
);
const PolicySuccessCTPL = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PolicySuccessCTPL")
);
const PaymentSuccessCTPLDone = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentSuccessCTPL")
);

const PaymentSuccessTravelDone = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentSuccessTravel")
);
const PaymentCustomerPayTravelDone = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentCustomerPayTravel")
);

// CYPHER PAYMENT ROUTING DATA START HERE
const PaymentSuccessTravelDoneCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentSuccessTravelCypher")
);

const PaymentFailedTravelCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentFailedTravelCypher")
);

const PaymentSuccessCTPLDoneCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentSuccessCtplCypher")
);

const PaymentCOCCTPLCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/CocPaymentFailedCtplCypher")
);

const PaymentFailedCTPLCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentFailedCtplCypher")
);

const PaymentFailedMotorCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentFailedMotorCypher")
);

const PaymentSuccessMotorDoneCypher = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentSuccessMotorCypher")
);

// CYPHER PAYMENT ROUTING DATA END HERE

const PaymentFailedCTPL = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentFailedCTPL")
);

const PaymentFailed = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/PaymentFailedTravel")
);

const TechnicalControlForCTPL = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/TechnicalControlForCTPL")
);

// PAYMENT LINK TO SEND CUSTOMER PAGE START HERE =====>

const PaymentLinkCustomerSend = React.lazy(() =>
  import("./components/OONA/Success/Success")
);
const PaymentLinkCustomerSendMotor = React.lazy(() =>
  import("./components/OONA/Success/SuccessMotor")
);
const PaymentLinkCustomerSendCTPL = React.lazy(() =>
  import("./components/OONA/Success/SuccessCTPL")
);

// PAYMENT LINK TO SEND CUSTOMER PAGE END HERE =====>

// POLICY AND MOTOR SUCCESS PAGE END HERE START HEREtechnical control

// POLICY AND MOTOR SUCCESS PAGE START HERE START HEREtechnical control
const TechnicalControlForMotor = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/TechnicalControlForMotor"
  )
);
const CustomerTechnicalControlForMotor = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/TechnicalControlForMotor"
  )
);

const FormalTechnicalControlForMotor = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/FormalTechnicalControlForMotor"
  )
);

const MotorSuccessQuotation = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/MotorSuccessQuotation"
  )
);

const motorQuotationPending = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/QuotationPendingMotor"
  )
);
const RiskInspectionRequiredMotor = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/RiskInspectionRequiredMotor"
  )
);

const motorPolicyPaymentPage = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/PolicySuccessMotor"
  )
);

const motorPolicyCustomerPayPage = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/PaymentCustomerPayMotor"
  )
);

const motorPolicyTechnicalControl = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/PolicyTechnicalControlMotor"
  )
);

const motorPolicySuccessPage = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/PaymentSuccessMotor"
  )
);

const CustomermotorPolicySuccessPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PaymentSuccessMotor"
  )
);
const CustomermotorPolicyFailedPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PaymentFailedMotor"
  )
);
const CustomermotorPolicyCustomerPayPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PaymentCustomerPayMotor"
  )
);
const motorPolicyFailedPage = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/SuccessQuotation/PaymentFailedMotor"
  )
);

// POLICY AND END SUCCESS PAGE END HERE START HEREtechnical control

const QuotationPending = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/QuotationPending")
);
const TravelTechnicalControl = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/travelTechnicalControl")
);
// this for the coca
const motorCoca = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/CocPaymentFailedMotor")
);
const CtplCoca = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/CocPaymentFailedCtpl")
);
const TravelCoca = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/CocPaymentFailedTravel")
);
const TravelQuotePending = React.lazy(() =>
  import("./components/OONA/TRAVEL/SuccessQuotation/TravelQuotePending")
);

// POLICY AND CTPL SUCCESS PAGE START HERE

const TravelerDetials = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelerDetials/TravelerDetials")
);

const ResourceCenter = React.lazy(() =>
  import("./components/SalesPitch/ResourceCenter")
);

const TravelBulkUpload = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelBulkUpload/TravelBulkUpload")
);
const TravelFleetUpload = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelBulkUpload/TravelFleetUpload")
);
const TravelPolicyGroup = React.lazy(() =>
  import("./components/OONA/TRAVEL/TravelPolicyGroup/TravelPolicyGroup")
);
const PolicyHolder = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/PolicyHolder/PolicyHolder")
);
const AlternatePolicyHolder = React.lazy(() =>
  import(
    "./components/OONA/MotorComprehensive/AlternatePolicyHolder/AlternatePolicyHolder"
  )
);

// pament status CTPL , MOTOR , TRAVEL
const GetPaymentStatus = React.lazy(() =>
  import("./components/OONA/GetPaymentStatus")
);
const MotorGetPaymentStatus = React.lazy(() =>
  import("./components/OONA/MotorGetPaymentStatus")
);
const CustomerMotorGetPaymentStatus = React.lazy(() =>
  import("./components/OONA/SharableLink/MotorGetPaymentStatus")
);
const TravelGetPaymentStatus = React.lazy(() =>
  import("./components/OONA/TravelGetPaymentStatus")
);

const TravelGetPaymentStatusCypher = React.lazy(() =>
  import("./components/OONA/TravelGetPaymentStatusCypher")
);
const CTPLGetPaymentStatusCypher = React.lazy(() =>
  import("./components/OONA/CTPLGetPaymentStatusCypher")
);

const MotorGetPaymentStatusCypher = React.lazy(() =>
  import("./components/OONA/MotorGetPaymentStatusCypher")
);

const MotorBulkUpload = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/MotorBulkUpload/MotorBulkUpload")
);
const MotorFleetUpload = React.lazy(() =>
  import("./components/OONA/MotorComprehensive/MotorBulkUpload/MotorFleetUpload")
);

// Cypher payment page ---

const CTPLCypherPayment = React.lazy(() =>
  import("./components/OONA/PaymentCypher/CtplCypher/CtplCypherPaymentSuccess")
);

const TravelCypherPayment = React.lazy(() =>
  import(
    "./components/OONA/PaymentCypher/TravelCypher/TravelCypherPaymentSuccess"
  )
);

const MotorCypherPayment = React.lazy(() =>
  import(
    "./components/OONA/PaymentCypher/MotorCypher/MotorCypherPaymentSuccess"
  )
);
const SharableLogin = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/SharableLogin/SharableLogin"
  )
);
const customerListingPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/Listing/QuotationsPoliciesMaster/quotationPolycies"
  )
);
const MobileLogin = React.lazy(() =>
  import("./components/OONA/SharableLink/components/SharableLogin/Mobilelogin")
);
const PolicyPage = React.lazy(() =>
  import("./components/OONA/SharableLink/components/PolicyPage/PolicyPage")
);
//
// const ShareableLinkProfile = React.lazy(() =>
//   import("./components/OONA/SharableLink/components/ShareableLinkProfile/ShareableLinkProfile")
// );

const CustomerMotorSuccessQuotation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/MotorSuccessQuotation"
  )
);

// ================ For Customer sharblelink start here =========================

const quoationPolicyTab = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/QuotationPolicyTab/QuotationPolicyTab"
  )
);

//
// motoshare for the customer will start from here
const motorCustomerPolicyHolder = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/PolicyHolder/PolicyHolder"
  )
);
const HypervergeTest = React.lazy(() =>
  import("./components/OONA/SharableLink/sharable_hyepr/PolicyHyper")
);
const motorCustomerAlternatePolicyHolder = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/AlternatePolicyHolder/AlternatePolicyHolder"
  )
);
const motorCustomerConfirmPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/MotorConfirmPage/MotorConfirmPage"
  )
);
const motorCustomerFormalTechnicalControl = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/FormalTechnicalControlForMotor"
  )
);

const motorCustomerPolicyTechnicalControl = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PolicyTechnicalControlMotor"
  )
);
const CustomermotorPolicyPaymentPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PolicySuccessMotor"
  )
);
const customermotorPolicyCustomerPayPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/motor_sharable_comp/SuccessQuotation/PaymentCustomerPayMotor"
  )
);
// motoshare for the customer will end here

const CustomerPolicyHolder1 = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/PolicyHolder/PolicyHolder1"
  )
);
const CustomerVehicalInformationPage1 = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/VehicalInformation/VehicalInformationPage1"
  )
);
const CustomerVehicalInformation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/VehicalInformation/VehicalInformation"
  )
);
const CustomerPolicyGroup = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/PolicyGroup/PolicyGroup"
  )
);
const SharableLinkCustomerInformation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/CustomerInformation/CustomerInformation"
  )
);
const CustomerConfirmPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/CustomerSharableLinkCTPL/ConfirmPage/ConfirmPage"
  )
);

const CustomerCTPLSuccessQuotation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/CTPLSuccessQuotation"
  )
);

const CustomerQuotationPending = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/QuotationPending"
  )
);
const CustomerPolicySuccessCTPL = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/PolicySuccessCTPL"
  )
);
const CustomerTechnicalControlForCTPL = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/TechnicalControlForCTPL"
  )
);
const CustomerPaymentFailedCTPL = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/PaymentFailedCTPL"
  )
);

const CustomerPaymentSuccessCTPL = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/PaymentSuccessCTPL"
  )
);

const CustomerGetPaymentStatus = React.lazy(() =>
  import("./components/OONA/SharableLink/components/GetPaymentStatus")
);
const CustomerCtplCoca = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/AllCTPLTravelPayment/SuccessQuotation/CocPaymentFailedCtpl"
  )
);
// ================ For Customer sharblelink end here =========================

// ================ For Agent Login Registration start here =========================
const AgentLogin = React.lazy(() =>
  import("./components/OONA/AgentComponents/AgentLoginResig/NewAgentLogin")
);
const AgentLoginNew = React.lazy(() =>
  import("./components/OONA/AgentComponents/AgentLoginResig/AgentLogin.js")
);
const NewRegister = React.lazy(() =>
  import("./components/OONA/AgentComponents/AgentLoginResig/NewRegister.js")
);
// ================ For Agent Login Registration end here =========================

// ================ Agent Form Registration start here ============================

const AgentForm = React.lazy(() =>
  import("./components/OONA/AgentComponents/AgentFormRegistration/AgentForm")
);
const CreateAccount = React.lazy(() =>
  import(
    "./components/OONA/AgentComponents/AgentFormRegistration/CreateAccount"
  )
);
const ApplicationStatus = React.lazy(() =>
  import(
    "./components/OONA/AgentComponents/AgentFormRegistration/ApplicationStatus"
  )
);
const VerifyDetails = React.lazy(() =>
  import(
    "./components/OONA/AgentComponents/AgentFormRegistration/VerifyDetails"
  )
);
const successProfile = React.lazy(() =>
  import(
    "./components/OONA/AgentComponents/AgentFormRegistration/successProfile.js"
  )
);




// +++++++++++++++++++ Shareable link for travel routes =============================

const SharableLinkQuoteCode = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/QuoteCode/QuoteCode"
  )
);
const ShareableTravelQuotePending = React.lazy(() =>
  import("./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/TravelQuotePending")
);


const ShareableLinkTravelInfo = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/TravelInfo/TravelInfo"
  )
);
const ShareableTravelPolicyGroup = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/TravelPolicyGroup/TravelPolicyGroup"
  )
);
const ShareableCustomerDetail = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/CustomerDetails/CustomerDetail"
  )
);
const ShareableTravelPage = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/TravelPage/TravelPage/TravelPage"
  )
);
const SharableSuccessQuotation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/SuccessQuotation"
  )
);
const ShareableCustomerInformation = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/CustomerInformation/CustomerInformation"
  )
);
const ShareableTravelerDetials = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/TravelerDetials/TravelerDetials"
  )
);
const ShareableFormalQuoteInfo = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/FormalQuoteInfo/FormalQuoteInfo"
  )
);
const ShareablePolicySuccessTravel = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/policySuccessTravel"
  )
);
const ShareableTravelTechnicalControl = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/travelTechnicalControl"
  )
);

const ShareableTravelGetPaymentStatus = React.lazy(() =>
  import("./components/OONA/SharableLink/components/TravelGetPaymentStatus")
);

const ShareableTravelGetPaymentStatusCypher = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/TravelGetPaymentStatusCypher"
  )
);

const ShareableTravelCypherPayment = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/PaymentCypher/TravelCypher/TravelCypherPaymentSuccess"
  )
);
const ShareablePaymentFailed = React.lazy(() =>
  import(
    "./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/PaymentFailedTravel"
  )
);
const ShareablePaymentSuccessTravelDone = React.lazy(() =>
  import("./components/OONA/SharableLink/components/ShareableLinkTravel/SuccessQuotation/PaymentSuccessTravel")
);

// ============= bulk issuance history ==============
const BulkIssuanceHistory = React.lazy(() =>
  import("./components/OONA/BulkUploadHistory/BulkUploadHistory")
);
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
const AuthorizationTokenError = React.lazy(() =>
  import("./components/OONA/SharableLink/AuthorizationFailedPage/AuthorizationFailedPage")
);


// HypervergeSDK
const HypervergeSDKListingMail = React.lazy(() =>
  import("./components/OONA/HyphervergeUploadListing/HypervergeSDK")
);

// thank you page 
const UploadedDocumentSuccess = React.lazy(() =>
  import("./HypervergeSDKListing/UploadedDocumentSuccess")
);

const paymentComplated = React.lazy(() =>
  import("./components/OONA/allPaymnetcomplated.js")
);
// CI ------- all ------------------ Routers -------------- start here ----
const CriticalIllness = React.lazy(() =>
  import("./components/OONA/CriticalIllness/criticalIllness.js")
);

const CommericalstructureCI = React.lazy(() =>
  import("./components/OONA/CriticalIllness/CommerialStructure/CommerialStructure.js")
);
const cipageurl = React.lazy(() =>
  import("./components/OONA/CriticalIllness/blankPage.js")
);


// CI ------- all ------------------ Routers -------------- End here ----

function App() {
  // const dispatch = useDispatch();
  // dispatch(actions.pwaOfflineFlag({ name: false }));

  return (

    <ReactKeycloakProvider authClient={keycloak}  >
      <React.Suspense fallback={<FullPageLoader fromapploader={true} />}>
      <Router>
        <div className="box-size">
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login" component={Login}/>

            <Route path="/forgotpassword" component={ForgotPassword}/>
            <Route path="/changepassword" component={ChangePassword}/>
            {/* <Route path="/Master/:masterType" component={Master}/> */}
            <Route path="/otp-verification" component={MobileLogin} />
            <Route
              path="/risk-inspection/:id/:type"
              component={riskInspection}
            />
            <Route
              path="/risk-inspection-success/:id/:type"
              component={riskInspectionSuccess}
            />
            <Route
              path="/inspection-file-upload/:id/:type"
              component={inspectionFileUpload}
            />
            <Route
              path="/document-already-uploaded/:id/:type"
              component={DocumentAlreadyUploaded}
            />
             <Route path="/cipageurl" component={cipageurl} />
            
            {/* thank you HypervergeSDK start here  */}
            <Route path="/HypervergeSDK" component={HypervergeSDKListingMail} />
            <Route path="/document-success" component={UploadedDocumentSuccess} />
            <Route path="/payment-completed" component={paymentComplated} />
            {/* thank you HypervergeSDK end here  */}

            {/* SEND TO CUSTOMER POLICY LIST TRAVEL, CTPL, MOTOR LINK START HERE  */}
            <Route path="/ctpl-cypher-payment" component={CTPLCypherPayment} />
            <Route
              path="/ctpl-payment-return-url-cyphyer"
              component={CTPLGetPaymentStatusCypher}
            />

            <Route
              path="/travel-cypher-payment"
              component={TravelCypherPayment}
            />
            <Route
              path="/travel-payment-return-url-cyphyer"
              component={TravelGetPaymentStatusCypher}
            />

            <Route
              path="/motor-cypher-payment"
              component={MotorCypherPayment}
            />

            <Route
              path="/motor-payment-return-url-cyphyer"
              component={MotorGetPaymentStatusCypher}
            />

            {/* success -- failed  */}
            <Route
              path="/travel-payment-success-cypher"
              component={PaymentSuccessTravelDoneCypher}
            />
            <Route
              path="/travel-payment-failed-cypher"
              component={PaymentFailedTravelCypher}
            />

            <Route
              path="/ctpl-payment-success-cypher"
              component={PaymentSuccessCTPLDoneCypher}
            />

            <Route
              path="/ctpl-payment-COC-cypher"
              component={PaymentCOCCTPLCypher}
            />

            <Route
              path="/ctpl-payment-failed-cypher"
              component={PaymentFailedCTPLCypher}
            />

            <Route
              path="/motor-payment-success-cypher"
              component={PaymentSuccessMotorDoneCypher}
            />
            <Route
              path="/motor-payment-failed-cypher"
              component={PaymentFailedMotorCypher}
            />

            {/* Customer sharbale link payment start */}

            <Route
              path="/customer-policyholder"
              component={motorCustomerPolicyHolder}
            />
            {/* <Route
          path="/test"
          component={HypervergeTest}
        /> */}
            <Route
              path="/customer-motor-convert-policy"
              component={CustomerMotorSuccessQuotation}
            />
            <Route
              path="/customer-motor-technical-control"
              component={CustomerTechnicalControlForMotor}
            />
            <Route path="/customer-listing" component={customerListingPage} />
            <Route
              path="/customer-alternate-policyholder"
              component={motorCustomerAlternatePolicyHolder}
            />
            <Route
              path="/customer-motor-confirm-page"
              component={motorCustomerConfirmPage}
            />
            <Route
              path="/customer-formal-technical-control"
              component={motorCustomerFormalTechnicalControl}
            />
            <Route
              path="/customer-motor-policy-technical-control"
              component={motorCustomerPolicyTechnicalControl}
            />

            <Route
              path="/customer-motor-payment-pay-now"
              component={CustomermotorPolicyPaymentPage}
            />
            <Route
              path="/customer-motor-policy-customer-pay"
              component={customermotorPolicyCustomerPayPage}
            />
            <Route
              path="/customer-motor-payment-success"
              component={CustomermotorPolicySuccessPage}
            />
            <Route
              path="/customer-motor-payment-failed"
              component={CustomermotorPolicyFailedPage}
            />
            <Route
              path="/customer-motor-policy-customer-pay"
              component={customermotorPolicyCustomerPayPage}
            />
            <Route
              path="/customer-motor-payment-return-url"
              component={CustomerMotorGetPaymentStatus}
            />

            {/* this is for the motore  */}
            {/* Shareable link for travel */}

            <Route
              path="/customer/quote-code"
              component={SharableLinkQuoteCode}
            />
            <Route path="/customer/travel-quotation-failed" 
            component={ShareableTravelQuotePending} />
            <Route
              path="/customer/travel-info"
              component={ShareableLinkTravelInfo}
            />
            <Route
              path="/customer/travel-policygroup"
              component={ShareableTravelPolicyGroup}
            />
            <Route
              path="/customer/travel-policy-holder"
              component={ShareableTravelPage}
            />
            <Route
              path="/customer/customer-detail"
              component={ShareableCustomerDetail}
            />
            <Route
              path="/customer/travel/success"
              component={SharableSuccessQuotation}
            />
            <Route
              path="/customer/customer-info"
              component={ShareableCustomerInformation}
            />
            <Route
              path="/customer/traveler-details"
              component={ShareableTravelerDetials}
            />
            <Route
              path="/customer/formal-quote-info"
              component={ShareableFormalQuoteInfo}
            />
            <Route
              path="/customer/travel-policy-sucess"
              component={ShareablePolicySuccessTravel}
            />
            <Route
              path="/customer/travel-technical-control"
              component={ShareableTravelTechnicalControl}
            />
            <Route
              path="/customer/travel-cypher-payment"
              component={ShareableTravelCypherPayment}
            />
            <Route
              path="/customer/travel-payment-return-url-cyphyer"
              component={ShareableTravelGetPaymentStatusCypher}
            />
            <Route
              path="/customer/travel-payment-return-url"
              component={ShareableTravelGetPaymentStatus}
            />
            <Route
              path="/customer/travel-payment-success"
              component={ShareablePaymentSuccessTravelDone}
            />
            <Route
              path="/customer/travel-payment-failed"
              component={ShareablePaymentFailed}
            />

            {/* Shareable link for travel */}

            <Route
              path="/quotation-policy-tabs"
              component={quoationPolicyTab}
            />
            <Route
              path="/customerr-policy-detail"
              component={CustomerPolicyHolder1}
            />
            <Route
              path="/customer-vehical-info"
              component={CustomerVehicalInformationPage1}
            />
            <Route
              path="/customer-vehicle-information"
              component={CustomerVehicalInformation}
            />
            <Route
              path="/customer-policy-group"
              component={CustomerPolicyGroup}
            />
            <Route
              path="/customer-sharableLink-information"
              component={SharableLinkCustomerInformation}
            />
            <Route
              path="/customer-confirm-details"
              component={CustomerConfirmPage}
            />
            <Route
              path="/customer-CTPL/success"
              component={CustomerCTPLSuccessQuotation}
            />
            <Route
              path="/customer-quotation-pending"
              component={CustomerQuotationPending}
            />
            <Route
              path="/customer-CTPL-policy-sucess"
              component={CustomerPolicySuccessCTPL}
            />
            <Route
              path="/customer-CTPL-technical-control"
              component={CustomerTechnicalControlForCTPL}
            />
            <Route
              path="/customer-CTPL-payment-failed"
              component={CustomerPaymentFailedCTPL}
            />
            <Route
              path="/customer-CTPL-payment-success"
              component={CustomerPaymentSuccessCTPL}
            />
            <Route
              path="/customer-ctpl-payment-return-url"
              component={CustomerGetPaymentStatus}
            />
            <Route path="/customer-ctpl-coca" component={CustomerCtplCoca} />
            <Route path="/customer-login" component={SharableLogin} />
            <Route path="/session-expire" component={AuthorizationTokenError} />
            {/* Customer sharbale link payment End */}

            {/* Agent LoginResigstration  start */}
            <Route path="/agent-login" component={AgentLogin} />
            <Route path="/agent-login-new" component={AgentLoginNew} />
            <Route path="/agent-resigstration" component={NewRegister} />
            {/* Agent LoginResigstration  end */}

            {/* Agent Form Resgistration start   */}
            <Route path="/agent-form" component={AgentForm} />
            {/* Agent Form Resgistration end   */}

            {/* Create account start   */}
            <Route path="/create-account" component={CreateAccount} />

            {/* Create account end   */}
            {/* SEND TO CUSTOMER POLICY LIST TRAVEL, CTPL, MOTOR LINK END HERE  */}
            {/* Application status start */}
            <Route path="/application-status" component={ApplicationStatus} />
            <Route path="/verify-details" component={VerifyDetails} />
            <Route path="/success-profile" component={successProfile} />
            
            {/* Application status end */}
            <div>
              {/* Make this route at the end only. */}
              <div>
                <Sidebar />
                <div style={{ marginTop: "60px" }}>
                  {/* <Route path="/clubsMaster" component={ClubsMaster}/>
                  <Route path="/birthday" component={Birthday}/>
                  <Route path="/home" component={HomePage}/> */}
                  <Route
                    path="/rewardscorner/contests/completed"
                    component={CompletedContest}
                  />
                  <Route
                    path="/rewardscorner/contests/allcontest"
                    component={AllContest}
                  />

                  <Route
                    path="/rewardscorner/contests/myoverallranking"
                    component={MyOverallRanking}
                  />
                  <Route
                    path="/rewardscorner/contests/activecontest"
                    component={ActiveContest}
                  />
                  <Route
                    path="/rewardscorner/contests/activecontestdetails"
                    component={ActiveContestDetails}
                  />
                  <Route path="/calendar" component={Calendar1} />
                  <Route path="/calendar/create-event" component={Calendar} />
                  <Route
                    path="/create-event-mobile"
                    component={CalendarMobile}
                  />

                  <Route path="/todo" component={TodoMobile} />

                  <Route path="/kpi-dashboard" component={KpiDashboard} />
                  <Route path="/daily-bussienss" component={DailyBussiness} />

                  <Route path="/notifypage" component={NotifyPage} />

                  <Route
                    path="/masterpresales/advisordetail/advisorpitch"
                    component={AdvisorPitch}
                  />
                  {/* <Route
                    path="/master/proposalfulfilment"
                    component={ProposalFulfilment}
                  /> */}
                  <Route
                    path="/master/prepaymentreview"
                    component={PrePaymentReview}
                  />
                  <Route
                    path="/master/paymentoptions"
                    component={PaymentOptions}
                  />
                  <Route path="/forcustomer" component={ForCustomer}/>
                  <Route path="/forself" component={ForSelf}/>
                  <Route
                    path="/renewalreport"
                    component={RenewalReport}
                  />
                  <Route
                    path="/masterpresales/customerdetails/salespitch"
                    component={SalesPitch}
                  />
                  <Route path="/resource-center" component={Resource}/>
                  <Route
                    path="/servicecorner/all"
                    component={ServiceCorner}
                  />
                  <Route
                    path="/servicecorner/self"
                    component={ServiceCornerSelf}
                  />
                  <Route
                    path="/servicecorner/customers"
                    component={ServiceCornerCustomers}
                  />
                  <Route
                    path="/renewalMaster/allRenewals"
                    component={Renewals}
                  />
                  <Route path="/renewalMaster/all" component={RenewalAll} />
                  <Route
                    path="/renewalMaster/paidRenewals"
                    component={RenewalPaid}
                  />
                  <Route
                    path="/renewalMaster/unpaidRenewals"
                    component={RenewalUnPaid}
                  />
                  <Route
                    path="/renewalMaster/lapsedRenewals"
                    component={RenewalLapsed}
                  />
                  <Route
                    path="/renewalMaster/Details"
                    component={RenewalMasterDetails}
                  />
                  <Route
                    path="/salespendency"
                    component={SalesPendency}
                  />
                  <Route
                    path="/mappedbranches"
                    component={MappedBranches}
                  />
                  <Route
                    path="/master/uploaddocuments"
                    component={UploadDocuments}
                  />
                  <Route
                    path="/master/proposalhistory"
                    component={ProposalHistory}
                  />
                  <Route path="/createtask" component={CreateTask} />
                  <Route path="/createnewtask" component={CreateNewTask} />
                  <Route
                    path="/leadMaster/:leadType"
                    component={LeadMaster}
                  />

                  {/* QuotationsPoliciesMaster and customerListingMaster master */}
                  <Route
                    path="/quotationsPoliciesMaster/:quotationsType"
                    component={QuotationsPoliciesMaster}
                  />
                  <Route
                    path="/all-motor-details"
                    component={AllmotorDetails}
                  />
                  <Route path="/all-ctpl-details" component={AllctplDetails} />
                  <Route path="/all-CI-details" component={AllCIDetails} />
                  <Route
                    path="/all-travel-details"
                    component={AlltravelDetails}
                  />

                  <Route
                    path="/all-motor-policy-details"
                    component={AllmotorpolicyDetails}
                  />
                  <Route
                    path="/all-ctpl-policy-details"
                    component={AllctplpolicyDetails}
                  />
                  <Route
                    path="/all-ci-policy-details"
                    component={AllcipolicyDetails}
                  />

                  <Route
                    path="/all-travel-policy-details"
                    component={AlltravelpolicyDetails}
                  />

                  <Route
                    path="/all-quotation-details"
                    component={AllQuotationDetails}
                  />

                  <Route
                    path="/advisormaster/:advisortype"
                    component={AdvisorMaster}
                  />
                  <Route path="/multichannel" component={Multichannel}/>
                  <Route
                    path="/defaultchannel"
                    component={DefaultChannel}
                  />
                  <Route path="/team" component={MyTeams}/>

                  <Route
                    path="/leadmasterpage/statuslead"
                    component={StatusLead}
                  />
                  {/* <Route
                    path="/oona-leadmanagement"
                    component={oonaCard}
                  /> */}

                  <Route
                    path="/leadmasterpage/leaddetails/personallead"
                    component={PersonalDetails}
                  />
                  <Route
                    path="/leadmasterpage/leaddetails/contactlead"
                    component={ContactDetails}
                  />
                  <Route
                    path="/leadmasterpage/leaddetails/professionallead"
                    component={ProfessionalDetails}
                  />
                  <Route
                    path="/leadmasterpage/leaddetails/existingLead"
                    component={ExistingInsurance}
                  />
                  <Route
                    path="/leadmasterpage/leaddetails/productLead"
                    component={ProposedProduct}
                  />
                  <Route
                    path="/leadmasterpage/proposal"
                    component={ProposalDetails}
                  />
                  <Route
                    path="/leadmasterpage/leadmasterdoc/leaddoc"
                    component={DocumentsUpload}
                  />
                  <Route
                    path="/leadmasterpage/leadhistory"
                    component={History}
                  />
                  <Route
                    path="/advisorOnboarding/:type"
                    component={AdvisorList}
                  />
                  <Route
                    path="/hypervergesdk"
                    component={sdkhyperverge}
                  />

                  {/* <Route
                    path="/PropsalFulfilment"
                    component={ProposalFulfilment}
                  /> */}
                  <Route path="/Oona-Dashboard" component={OonaDashboard} />
                </div>
              </div>

              <Route path="/products" component={LoanProducts} />
              <Route path="/bulkaction" component={BulkAction} />
            </div>
          </Switch>
        </div>

        {/* New url and link rout start here*/}
        {/* <Route path="/product" component={ProductSelect}/> */}
        <Route path="/vehical-type" component={VehicalType} />
        <Route path="/policy-type" component={PolicyType} />
        <Route path="/vehicle-information" component={VehicalInformation} />
        <Route path="/travel-policy-holder" component={TravelPage} />
        <Route path="/travel-info" component={TravelInfo} />
        <Route path="/customer-detail" component={CustomerDetail} />

        {/* travel motor and ctpl success page START*/}
        <Route path="/travel/success" component={SuccessQuotation} />
        <Route path="/CTPL/success" component={CTPLSuccessQuotation} />
        <Route path="/travel-policy-sucess" component={PolicySuccessTravel} />
        <Route path="/CTPL-policy-sucess" component={PolicySuccessCTPL} />
        <Route
          path="/CTPL-payment-success"
          component={PaymentSuccessCTPLDone}
        />
        <Route path="/CTPL-payment-failed" component={PaymentFailedCTPL} />

        <Route
          path="/travel-payment-success"
          component={PaymentSuccessTravelDone}
        />

        {/* <Route
          path="/customer/shareable-link"
          component={ShareableLinkProfile}
        /> */}
        {/* Credit term Success */}
        <Route
          path="/travel-policy-customer-pay"
          component={PaymentCustomerPayTravelDone}
        />

        <Route path="/travel-payment-failed" component={PaymentFailed} />
        <Route path="/travel-quotation-failed" component={TravelQuotePending} />
        <Route
          path="/travel-technical-control"
          component={TravelTechnicalControl}
        />
        <Route path="/motor-coca" component={motorCoca} />
        <Route path="/ctpl-coca" component={CtplCoca} />
        <Route path="/travel-coca" component={TravelCoca} />

        <Route
          path="/CTPL-technical-control"
          component={TechnicalControlForCTPL}
        />
        <Route path="/quotation-pending" component={QuotationPending} />
        {/* travel motor and ctpl success page END*/}

        {/* Payment link send to customer page start here */}

        <Route
          path="/travel-customer-payment-link"
          component={PaymentLinkCustomerSend}
        />
        <Route
          path="/motor-customer-payment-link"
          component={PaymentLinkCustomerSendMotor}
        />
        <Route
          path="/ctpl-customer-payment-link"
          component={PaymentLinkCustomerSendCTPL}
        />
        <Route path="/links" component={Links} />

        {/* Payment link send to customer page end here */}

        {/* this is for the motor technical control, pending quotation, success and policy quote start*/}
        <Route
          path="/motor-technical-control"
          component={TechnicalControlForMotor}
        />

        <Route
          path="/formal-technical-control"
          component={FormalTechnicalControlForMotor}
        />
        <Route path="/motor-convert-policy" component={MotorSuccessQuotation} />
        <Route
          path="/motor-quotation-pending"
          component={motorQuotationPending}
        />
        <Route
          path="/risk-inspection-required"
          component={RiskInspectionRequiredMotor}
        />

        <Route
          path="/motor-payment-pay-now"
          component={motorPolicyPaymentPage}
        />
        {/* customer payment */}

        <Route
          path="/motor-policy-customer-pay"
          component={motorPolicyCustomerPayPage}
        />

        <Route
          path="/motor-policy-technical-control"
          component={motorPolicyTechnicalControl}
        />
        <Route
          path="/motor-payment-success"
          component={motorPolicySuccessPage}
        />

        <Route path="/motor-payment-failed" component={motorPolicyFailedPage} />

        {/* this is for the motor technical control, pending quotation, success and policy quote start*/}

        {/* ALL ---------- MOTOR PAGE ROUTING START HERE ----------- */}
        <Route path="/motor-info" component={MotorComprehensive} />
        <Route path="/price-check-info" component={PriceCheck1} />
        <Route path="/policy-group-info" component={PolicyGroupDetails} />
        <Route path="/motor-bulk" component={MotorBulkUpload} />
        <Route path="/motor-fleet" component={MotorFleetUpload} />

        <Route
          path="/vehicle-information-info"
          component={VehicleInformationInfo}
        />
        <Route path="/vehicle-Quote-info" component={vehicleQuote} />
        <Route path="/accessory-Quote-info" component={accessoryQuote} />
        <Route path="/coverage-Quote-info" component={coverageQuote} />
        <Route path="/policyholder-Quote-info" component={policyHolderQuote} />
        <Route path="/Quote-info" component={quoteInformation} />
        <Route path="/success-Quote-info" component={QuotationSuccess} />
        <Route path="/policyholder" component={PolicyHolder} />
        <Route
          path="/alternate-policyholder"
          component={AlternatePolicyHolder}
        />
        <Route path="/motor-confirm-page" component={MotorConfirmPage} />
        {/* ALL ---------- MOTOR PAGE ROUTING END HERE ----------- */}

        {/* routing for the customer start from here for the motoshare */}

        {/* routing for the customer end==> from here for the motoshare */}

        {/* PAYMENT RETURN URL MOTOR, CTPL, TRAVEL*/}
        <Route path="/ctpl-payment-return-url" component={GetPaymentStatus} />
        
        <Route
          path="/travel-payment-return-url"
          component={TravelGetPaymentStatus}
        />

        <Route
          path="/motor-payment-return-url"
          component={MotorGetPaymentStatus}
        />


        {/* ALL ---------- ALL OTHER DASHBORD CARD CTPL PAGE ROUTING START HERE ----------- */}
        <Route path="/all-products" component={AllProduct} />
        <Route path="/customer-info" component={CustomerInformations} />
        <Route path="/customer-information" component={CustomerInformation} />
        <Route path="/loginProfile" component={OonaLogin} />
        {/* <Route path="/success" component={Success} /> */}
        <Route path="/technical-control" component={TechnicalControl} />
        <Route path="/error" component={Fail} />
        <Route path="/confirm-details" component={ConfirmPage} />
        <Route path="/help-desk" component={HelpCenter} />
        <Route path="/oona-notification" component={NotificationOona} />
        <Route path="/vehical-info" component={VehicalInformationPage1} />
        <Route path="/policy-group" component={PolicyGroup} />
        <Route path="/ctpl-bulk-upload" component={BulkUpload} />
        <Route path="/ctpl-fleet-upload" component={FleetUpload} />
        
        {/* ============================ motor select product ================ */}
        <Route path="/motor-select-product" component={MotorSelectedProduct} />
        <Route path="/policy-detail" component={PolicyHolder1} />
        <Route path="/motor-bulk-upload" component={BulkUploadMotor} />
        <Route path="/performance" component={Performance} />

        {/* Bulk Upload Fine start here  */}

        <Route
          path="/ctpl-bulk-upload-success"
          component={CtplBulkUploadSuccess}
        />

        <Route path="/ctpl-bulk-upload-error" component={CtplBulkUploadError} />
        <Route
          path="/travel-bulk-upload-success"
          component={TravelBulkUploadSuccess}
        />
        <Route
          path="/travel-bulk-upload-error"
          component={TravelBulkUploadError}
        />
        <Route
          path="/motor-bulk-upload-success"
          component={MotorBulkUploadSuccess}
        />
        <Route
          path="/motor-bulk-upload-error"
          component={MotorBulkUploadError}
        />
        {/* Bulk Upload Fine End here  */}
        <Route path="/formal-quote-info" component={FormalQuoteInfo} />
        <Route
          path="/quickpolicy-information"
          component={QuotePolicyInformation}
        />
        <Route path="/quick-quote-info" component={QuickQuoteInfo} />
        <Route path="/success-quickpolicy" component={SuccessQuickPolicy} />
        <Route path="/customer-details" component={OonaCustomerListing} />
        <Route path="/quote-code" component={QuoteCode} />
        {/* this is for the customer_motor  */}

        <Route path="/customer-card-details" component={CustomerList} />
        <Route path="/traveler-details" component={TravelerDetials} />
        {/* ALL ---------- ALL OTHER DASHBORD CARD CTPL PAGE ROUTING START HERE ----------- */}

        {/* ootb  */}
        <Route path="/learningcenter" component={ResourceCenter} />
        <Route path="/travel-bulkUpload" component={TravelBulkUpload} />
        <Route path="/travel-fleetUpload" component={TravelFleetUpload} />
        
        <Route path="/travel-policygroup" component={TravelPolicyGroup} />

        {/* coc authentication failed */}
        {/* <Route path="/travel-coc-failed" component={CocPaymentFailedTravel} />
     <Route path="/ctpl-coc-failed" component={CocPaymentFailedCtpl} />
     <Route path="/motor-coc-failed" component={CocPaymentFailedMotor} /> */}

        {/* bulkupload history start */}
        <Route path="/bulk-issuance-history" component={BulkIssuanceHistory} />
        
        
        {/* bulkupload history end */}

        {/* shareablelogin */}
        {/* <Route path="/customer-login" component={SharableLogin} /> */}
        
        <Route path="/policy" component={PolicyPage} />

        {/* Bulk History Details Table routing*/}
        <Route path="/bulk-history-details" component={bulkHistoryDetails} />

        {/* all ------- CI ------------ Routers ----------------------- start ------------- here  */}
          <Route path="/critical-illness" component={CriticalIllness} />
          <Route path="/commercial-structure" component={CommericalstructureCI} />
           
        {/* all ------- CI ------------ Routers ----------------------- end ------------- here  */}

      </Router>
    </React.Suspense>
    </ReactKeycloakProvider>
    
  );
}

export default App;
